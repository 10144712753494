export async function saveCategorization (uid, requestIdent, taxonomyId, taxonomyPath, selectedLanguage) {
    const formData = new FormData();
    formData.append("uid", uid)
    formData.append("ident", requestIdent)
    formData.append("taxonomyId", taxonomyId)
    formData.append("taxonomyPath", taxonomyPath)
    formData.append("language", selectedLanguage)
    const response = fetch('./requests/save-categorization.php', {
      method: 'POST',
      body: formData
    });
    //console.log('saveCat '+response)
  }