import React, { useState, useLayoutEffect } from 'react';
import { Link } from "react-router-dom";

export default function Refund() {
    const [showLogin, setShowLogin] = useState(false)
  
  
    useLayoutEffect(() => {
      if(sessionStorage.loggedIn === undefined){
        setShowLogin(true)
      }
    },[])
  
  
    return (
        <div className="dashboard">
          <header>
            <h1>Refund policy</h1>
            <Link to="/">Back to Home</Link>
          </header>
          <main>
            <section className="terms-block">
            <p>
                Last Updated: Aug 12, 2024
            </p>
            <p>

                Thank you for using WTFF.com. We strive to provide the best possible service for our users. However, we understand that there may be occasions where you may not be satisfied with our products or services. Please review our refund policy carefully.
                </p>
            <p>

                1. General Refund Policy
                </p>
            <p>

                Due to the nature of our services, all sales are final. We do not offer refunds once a service has been rendered or digital products have been delivered. However, we are committed to ensuring your satisfaction and will work with you to resolve any issues you may encounter.
                </p>
            <p>

                2. Eligibility for Refunds
                </p>
            <p>

                2.1. Service Interruptions: If there is a significant service interruption or technical issue that prevents you from using our services for an extended period, you may be eligible for a partial refund or credit. Please contact our support team to discuss the specific situation.
                </p>
            <p>

                2.2. Duplicate Charges: If you believe you have been erroneously charged more than once for the same service, please notify us immediately. We will investigate the matter and issue a refund for any duplicate charges.
                </p>
            <p>

                3. Subscriptions
                </p>
            <p>

                3.1. Cancellations: You may cancel your subscription at any time through your account settings. Cancellation will take effect at the end of the current billing cycle, and you will not be charged for the subsequent billing period. We do not offer prorated refunds for partial months or unused portions of a subscription.
                </p>
            <p>

                3.2. Auto-Renewal: Subscriptions automatically renew at the end of each billing cycle. If you do not wish to renew your subscription, you must cancel before the renewal date. No refunds will be issued for cancellations made after the renewal date has passed.
                </p>
            <p>

                4. How to Request a Refund
                </p>
            <p>

                To request a refund, please contact our <Link to={showLogin ? "/login" : "/contact"}>customer support team</Link> with the following information:
            <ul>
                <li>Your full name</li>
                <li>Your account username or email address</li>
                <li>The date of the transaction</li>
                <li>The reason for your refund request</li>
            </ul>
                5. Processing Refunds
                </p>
            <p>

                We will review your refund request and notify you of the outcome within 5 business days. If your refund is approved, we will process the refund to the original payment method used for the purchase. Please note that it may take an additional 5 business days for the refund to be reflected in your account, depending on your payment provider.
                </p>
            <p>


                By using our services, you acknowledge that you have read, understood, and agreed to this Refund Policy. We appreciate your business and are dedicated to providing you with excellent customer service.
            </p>
         
            </section>
          </main>
        </div>

 )
}