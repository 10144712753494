import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

function Affiliate() {
    const [message, setMessage] = useState('');
    const [confirmation, setConfirmation] = useState('');
    const [showLogin, setShowLogin] = useState(false)
    const navigate = useNavigate(); 
  

  useLayoutEffect(() => {
    if(sessionStorage.loggedIn === undefined){
      setShowLogin(true)
    }
  },[])
  

  const getAffiliateId = useCallback(() => {
    if(showLogin){
        navigate('/login')

    }
    else{
      const uid = sessionStorage.getItem('uid')
      const url = './get-affiliate-id.php'
      
      if(uid !== ''){
          const formData = new FormData();
          formData.append("userId", uid);
          const body = {
              userId: uid,
          };
          
          const requestMetadata = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': ''
              },
              body: JSON.stringify(body)
          };
    
          fetch(url, requestMetadata)
          .then(async response => {
            const data = await response.text();
            //console.log('data1 '+ data)
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            else{
                if(data != 2){
                    console.log('data2 '+ data)  
                    setConfirmation('This is you affiliate URL: https://wtff.com/?inv='+data);
                    setMessage(''); // Clear the textarea
                }  
                else{
                    setConfirmation('But something went wrong. Please try again or contact us.');
                    setMessage(''); // Clear the textarea

                }         
      
            }
        })
        .catch(error => {
            //this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });      
      }
    }
  },[showLogin])


  return (
    <div className="dashboard">
      <header>
        <h1>Affiliate</h1>
        <Link to="/">Back to Home</Link>
      </header>
     <main>
     <section className="privacy-block">

      <div className="affiliate-container">
       <h2>Endless Earning Possibilities</h2>
       <p>
        Join WTFF.com, the ultimate web content creation tool, as an affiliate and unlock endless earning possibilities. 
        Promote our innovative platform and earn commissions for every successful invitee. 
        Whether you're a blogger, marketer, or content creator, our affiliate program is designed to help you maximize your income potential.
      </p>
      <p>
        You will earn 50% of the amount spent by your invitees. Payments are processed once a month, 
        and the minimum fee on your account with us should be at least USD 20. 
        Currently, payments are transferred via PayPal, with cryptocurrency transfers coming soon.
      </p>
      <h2>Become an Affiliate Today!</h2>
        
        <div className="affiliate-btn" onClick={getAffiliateId}>Request your affiliate URL</div>
        
        {confirmation && <div className="affiliate-confirmation-message">
            Congratulations! You can start to earn right now. <div>{confirmation}</div>
        </div>}
    </div>
   </section>
  </main>
</div>    

  );
}

export default Affiliate;