import React, { useState, useLayoutEffect } from 'react';
import { Link } from "react-router-dom";

export default function Terms() {
    const [showLogin, setShowLogin] = useState(false)
  
  
    useLayoutEffect(() => {
      if(sessionStorage.loggedIn === undefined){
        setShowLogin(true)
      }
    },[])
  
  
    return (
        <div className="dashboard">
          <header>
            <h1>Terms of service</h1>
            <Link to="/">Back to Home</Link>
          </header>
          <main>
            <section className="terms-block">



                <p> 
                    Last Updated: Aug 12, 2024
                </p>
                <p>
                    Welcome to WTFF.com. By using our website, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.
                </p>
                <p>

                    1. Acceptance of Terms
                    </p>
                <p>

                    By accessing or using WTFF.com, you agree to be bound by these Terms of Service ("Terms") and our Privacy Policy. If you do not agree with these terms, you are not authorized to use our Service.
                </p>
                <p>
                    2. Description of Service
                    </p>
                <p>

                    WTFF.com provides online services to quickly create e-commerce related content, including, but not limited to: product descriptions, product images suggestions, leads to related third-party videos, etc. ("the Service").
                </p>
                <p>

                    3. User Accounts
                    </p>
                <p>

                    3.1. Registration: To access certain features of the Service, you may be required to register for an account. You must provide accurate and complete information during the registration process.
                    </p>
                <p>

                    3.2. Account Security: You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
                    </p>
                <p>

                    4. Use of the Service
                    </p>
                <p>

                    4.1. License: We grant you a limited, non-exclusive, non-transferable license to access and use the Service for your personal or business use in accordance with these Terms.
                    </p>
                <p>

                    4.2. Prohibited Conduct: You agree not to:
                    </p>
                <p>
                <ul>
                    <li>Use the Service for any illegal purpose.</li>
                    <li>Violate any laws or regulations.</li>
                    <li>Infringe on the intellectual property rights of others.</li>
                    <li>Distribute malware or engage in malicious activities.</li>
                    <li>Interfere with or disrupt the Service.</li>
                </ul>    
                    </p>
                <p>


                    5. Content
                    </p>
                <p>

                    5.1. User-Generated Content: You are solely responsible for any content you submit, post, or upload through the Service. By submitting content, you grant us a worldwide, royalty-free, non-exclusive license to use, reproduce, modify, and distribute your content in connection with the Service.
                    </p>
                <p>

                    5.2. Third-Party Content: The Service may include links to third-party websites or content. We do not endorse and are not responsible for any third-party content, and we shall not be liable for any loss or damage incurred as a result of your use of any third-party content.
                    </p>
                <p>


                    6. Payment and Subscriptions
                    </p>
                <p>

                    6.1. Fees: Some features of the Service may require payment of fees. You agree to pay all applicable fees in accordance with the terms set forth on our website.
                    </p>
                <p>

                    6.2. Subscriptions: If you subscribe to a paid portion of the Service, your subscription will automatically renew at the end of each billing cycle unless you cancel. You may cancel your subscription at any time through your account settings.
                    </p>
                <p>

                    7. Disclaimers and Limitation of Liability
                    </p>
                <p>

                    7.1. Disclaimers: The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied. We disclaim all warranties, including but not limited to, merchantability, fitness for a particular purpose, and non-infringement.
                    </p>
                <p>

                    7.2. Limitation of Liability: To the maximum extent permitted by law, WTFF.com shall not be liable for any indirect, incidental, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, or goodwill, arising from your use of the Service.
                    </p>
                <p>

                    8. Indemnification
                    </p>
                <p>

                    You agree to indemnify and hold harmless WTFF.com, its officers, directors, employees, and agents, from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of the Service or violation of these Terms.
                    </p>
                <p>

                    9. Changes to the Terms
                    </p>
                <p>

                    We reserve the right to modify or update these Terms at any time. We will notify you of any changes by posting the new Terms on our website. Your continued use of the Service after any changes constitutes your acceptance of the new Terms.
                    </p>
                <p>

                    10. Termination
                    </p>
                <p>

                    We may terminate or suspend your account and access to the Service, without prior notice or liability, for any reason, including if you breach these Terms.
                    </p>
                <p>

                    11. Governing Law
                    </p>
                <p>

                    These Terms shall be governed by and construed in accordance with the laws of EU, without regard to its conflict of law principles.
                    </p>
                <p>

                    12. Contact Information
                    </p>
                <p>

                    If you have any questions about these Terms, please <Link to={showLogin ? "/login" : "/contact"}>contact us</Link>.
                    </p>
                <p>

                    By using WTFF.com, you acknowledge that you have read, understood, and agreed to these Terms of Service.
                </p>
    </section>
    </main>
    </div>

 )
}