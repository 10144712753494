export async function requestCategorization (uid, requestIdent, requestTitle, selectedLanguage) {
    const formData = new FormData();
    formData.append("uid", uid)
    formData.append("ident", requestIdent)
    formData.append("title", requestTitle)
    formData.append("language", selectedLanguage)
    const response = await fetch('./requests/categorization.php', {
      method: 'POST',
      body: formData
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.text();
    //console.log('Categorization:', data);
    //return {__html: data}
    return JSON.parse(JSON.parse(data))
  }